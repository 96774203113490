import "./Prices.css";
import React, { useState } from "react";
import { Accordion, Panel } from "rsuite";

import openIcon from "../../images/Main/union.svg";
import "rsuite/dist/rsuite-no-reset.min.css";

const Prices = () => {
  const questions = [
    {
      title: "Дизайн-проект интерьера",
      price: "от 5 000 руб. за м2",
    },
    {
      title: "Авторский надзор",
      price: "от 50 000 руб. в месяц",
    },
    {
      title: "Генеральный подряд",
      price: "от 100 000 руб. в месяц",
    },
    {
      title: "Ремонтно-отделочные работы",
      price: "от 40 000 руб. за м2",
    },
  ];
  const [activeKey, setActiveKey] = useState(null);

  const handleSelect = (key) => {
    setActiveKey(activeKey === key ? null : key); // Открытие/закрытие панели
  };
  return (
    <section className="prices">
      <h2 className="contacts__title">Стоимость услуг студии дизайна СОДА</h2>
      <Accordion activeKey={activeKey} onSelect={handleSelect}>
        {questions.map((data, index) => (
          <Panel
            key={index}
            eventKey={index}
            header={
              <div
                className={`design__element prices__element ${
                  activeKey === index ? "design__element_active" : ""
                }`}
              >
                <h3 className="question__question prices__question">
                  {data.title} <br className="prices__wrap" /> {data.price}
                </h3>
                <button
                  type="button"
                  className={`button questions__button ${
                    activeKey === index ? "questions__button_active" : ""
                  }`}
                >
                  <img
                    src={openIcon}
                    className={`question__open-image ${
                      activeKey === index ? "questions__open-image_active" : ""
                    }`}
                    alt="Значок вверх"
                  />
                </button>
              </div>
            }
          >
            <div className="questions__answer questions__answer_active">
              <div className="service__answer">
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Анализ объекта</li>
                  <li className="policy__element servise__answer-text">
                    Дизайнер выезжает на объект либо по вашим чертежам проверяет
                    планировку и все коммуникации.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Обмерный план</li>
                  <li className="policy__element servise__answer-text">
                    Обмерный план включает в себя точные замеры стен, мест
                    расположения колонн, выступающих конструкций и их высот.
                    Также на обмерном плане отображено расположение окон,
                    дверных проемов, указаны места проходимых коммуникаций. Это
                    является исходной информацией, необходимой для разработки
                    планировочного решения.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Техническое задание</li>
                  <li className="policy__element servise__answer-text">
                    Дизайнер на встрече с вами выясняет всю необходимую для
                    выполнения проекта информацию, такую как: состав семьи,
                    увлечения, гости, необходимое к размещению оборудование.
                    Также на встрече вы определяете стилистическое направление
                    вашего интерьера.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Планировочное решение
                  </li>
                  <li className="policy__element servise__answer-text">
                    Дизайнер на основании Технического задания, художественного
                    вкуса, норм эргономики и учитывая собственный опыт
                    разрабатывает до 3-х вариантов планировок/Вы видите как на
                    планах развивается пространство, показаны различные
                    комбинации по зонированию и наполнению помещений. На виде
                    сверху отображены условно предметы мебели и декора,
                    становятся отчетливыми проходные зоны и функционал.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Бюджетирование</li>
                  <li className="policy__element servise__answer-text">
                    На этом этапе мы определяем общий бюджет вашего объекта,
                    чтобы при создании проекта дизайнер использовал отделочные
                    материалы, производителей мебели, освещения, сантехники,
                    которые подходят вам по бюджету.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Фотореалистичная 3d-визуализация
                  </li>
                  <li className="policy__element servise__answer-text">
                    Модель вашего будущего интерьера, сделанная на компьютере,
                    на которых вы наглядно видите, как будет выглядеть ваш
                    интерьер, после окончания ремонтных работ.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Комплектация проекта
                  </li>
                  <li className="policy__element servise__answer-text">
                    Таблица с отделочными материалами, мебелью и декором.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Рабочая документация для строителей
                  </li>
                  <li className="policy__element servise__answer-text">
                    План демонтажа перегородок и инженерных коммуникаций;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План возводимых перегородок с маркировкой дверных проёмов;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План зонирования помещений с указанием площадей;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План расстановки мебели и сантехнического оборудования;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План полов с указанием типа напольных покрытий и площадей;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План размещения подогрева пола с привязкой регулятора;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План размещения сантехнического оборудования с габаритными
                    размерами и привязками;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Планы потолков с указанием типа потолка, размеров и сечений;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План размещения осветительных приборов, с привязкой
                    освещения и расстановки мебели;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План привязки выключателей с указанием включения групп
                    светильников;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План электрики и электро-выводов с привязками;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Развёртка стен с раскладкой кафельной плитки с указанием
                    размеров (количество чертежей зависит от уровня сложности);
                  </li>
                  <li className="policy__element servise__answer-text">
                    Спецификация заполнения дверных проёмов;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Ведомость отделки помещений;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Схема отделки помещений;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План размещения принудительной вентиляции;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Составление плана привязки внутренних и внешних блоков
                    систем кондиционирования;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Разработка нестандартных элементов интерьера;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Детальный подбор материалов и мебели с артикулами;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Разработка индивидуальной мебели.
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        ))}
      </Accordion>
    </section>
  );
};

export default Prices;
