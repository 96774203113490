import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Main from "../Main/Main";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Gallery from "../Gallery/Gallery";
import Object from "../Object/Object";
import Popup from "../Popup/Popup";
import Policy from "../Policy/Policy";
import Contacts from "../Contacts/Contacts";
import About from "../About/About";
import Aparts from "../Aparts/Aparts";
import Houses from "../Houses/Houses";
import Prices from "../Prices/Prices";
import { gallery } from "../../utils/gallery";
import NotFound from "../NotFound/NotFound";
import Preloader from "../Preloader/Preloader";

function App() {
  const [isBurger, setBurger] = useState(false);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupParam, setPopupParam] = useState({
    title: "",
    text: "",
    inputs: [],
  });

  const handleSwitchBurger = () => {
    setBurger(!isBurger);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  function handleOverlayClick(e) {
    if (e.target === e.currentTarget) {
      closePopup();
    }
  }

  const handleOpenPopup = () => {
    setPopupOpen(true);
    setPopupParam({
      title: "Получите точную стоимость дизайн-проекта",
      text: "Мы предоставим расчет полной стоимости проекта и бесплатную консультацию дизайнера у Вас на объекте",
      inputs: ["name", "phone", "area"],
    });
  };

  const handleOpenPopupRequest = () => {
    setPopupOpen(true);
    setPopupParam({
      title: "Оставить заявку",
      text: "Заполните форму и наш менеджер свяжется с Вами",
      inputs: ["name", "phone"],
    });
  };

  return (
    <div className={`root`}>
      <div className={`page`}>
        <Header
          isBurger={isBurger}
          handleOpenPopup={handleOpenPopup}
          handleSwitchBurger={handleSwitchBurger}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Main
                handleOpenPopup={handleOpenPopup}
                handleOpenPopupRequest={handleOpenPopupRequest}
              />
            }
          />
          <Route path="/gallery" element={<Gallery gallery={gallery} />} />
          <Route
            path="/gallery/realized"
            element={<Gallery gallery={gallery} />}
          />
          <Route
            path="/gallery/design"
            element={<Gallery gallery={gallery} />}
          />
          <Route path="/gallery/:name" element={<Object />} />
          <Route path="/price" element={<Prices />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/about-us" element={<About />} />
          <Route
            path="/services/apartments"
            element={
              <Aparts
                handleOpenPopup={handleOpenPopup}
                handleOpenPopupRequest={handleOpenPopupRequest}
              />
            }
          />
          <Route
            path="/services/houses"
            element={
              <Houses
                handleOpenPopup={handleOpenPopup}
                handleOpenPopupRequest={handleOpenPopupRequest}
              />
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        {/* <Preloader /> */}
        <Popup
          onOverlay={handleOverlayClick}
          isOpen={isPopupOpen}
          onClose={closePopup}
          popupParam={popupParam}
        />
      </div>
    </div>
  );
}

export default App;
