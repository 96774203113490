import React, { useState, useEffect } from "react";
import "./ImagePopup.css";
import arrow from "../../images/Main/arrow.svg";

const ImagePopup = ({ images, currentIndex, onClose }) => {
  const [index, setIndex] = useState(currentIndex);

  const handlePrev = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        handlePrev();
      } else if (e.key === "ArrowRight") {
        handleNext();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="popup-image">
      <div className="popup-image__container" onClick={onClose}>
        <button
          className="image-popup-button image-popup-button--prev"
          onClick={(e) => {
            e.stopPropagation();
            handlePrev();
          }}
        >
          <img src={arrow} className="popup-image__arrow" alt="стрелка влево" />
        </button>
        <img
          src={images[index]}
          alt={`Фото ${index + 1}`}
          className="image-popup-image"
          onClick={(e) => e.stopPropagation()}
        />
        <button
          className="image-popup-button image-popup-button--next"
          onClick={(e) => {
            e.stopPropagation();
            handleNext();
          }}
        >
          <img
            src={arrow}
            className="popup-image__arrow"
            alt="стрелка вправо"
          />
        </button>
        <div className="image-popup-indicator">
          {index + 1}/{images.length}
        </div>
      </div>
    </div>
  );
};

export default ImagePopup;
