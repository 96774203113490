import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { gallery } from "../../utils/gallery";
import "./Object.css";
import "img-comparison-slider";
import LoadableImage from "../LoadableImage/LoadableImage";
import ImagePopup from "../ImagePopup/ImagePopup";

import arrowLeft from "../../images/Object/arrow-left.svg";
import manuscriptImage from "../../images/Object/manuscript.svg";

import CustomVideoPlayer from "../CustomVideoPlayer/CustomVideoPlayer";

import useWindowSize from "../../hooks/useWindowSize";
import NotFound from "../NotFound/NotFound";

const Object = () => {
  const { name } = useParams();
  const project = gallery.find((proj) => proj.name === name);
  const navigate = useNavigate();
  const location = useLocation();

  const [popupIndex, setPopupIndex] = useState(null);

  const size = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBackClick = () => {
    if (location.state?.from) {
      navigate(-1);
    } else {
      navigate("/gallery");
    }
  };

  const openImagePopup = (index) => {
    console.log(1);
    setPopupIndex(index);
  };

  const closeImagePopup = () => {
    setPopupIndex(null);
  };

  const handleImageClick = (index) => {
    openImagePopup(index);
  };

  if (!project) {
    return <NotFound />;
  }

  return (
    <section className="object">
      <div className="object__header">
        <button className="object__button" onClick={handleBackClick}>
          <img
            className="object__button-image"
            src={arrowLeft}
            alt="Стрелка вправо"
          />
          Назад
        </button>
        <LoadableImage
          className="object__manuscript"
          src={manuscriptImage}
          alt="Рукопись «Ваше новое качество жизни»"
        />
      </div>
      <div className="object__content">
        <div className="object__container">
          <h2 className="object__title">{project.title}</h2>
          <div className="object__columns">
            <LoadableImage
              className="object__image"
              src={project.intro2 ? project.intro2 : project.intro}
              alt={project.apart}
            />
            <div className="object__column">
              <p className="object__text">{project.text}</p>
              <ul className="object__params">
                <li className="object__param">
                  Площадь проекта{" "}
                  <span className="object__value">{project.metrs} м²</span>
                </li>
                <li className="object__param">
                  Стоимость проекта{" "}
                  <span className="object__value">{project.price} руб.</span>
                </li>
                <li className="object__param">
                  Стоимость реализации{" "}
                  <span className="object__value">
                    {project.priceRealization} руб.
                  </span>
                </li>
                {project.projectDevelopment && (
                  <li className="object__param">
                    Разработка проекта{" "}
                    <span className="object__value">
                      {project.projectDevelopment} дней
                    </span>
                  </li>
                )}
                {project.realizationPeriod && (
                  <li className="object__param">
                    Сроки реализации{" "}
                    <span className="object__value">
                      {project.realizationPeriod} мес.
                    </span>
                  </li>
                )}
                {project.projectDevelopment && (
                  <li className="object__param">
                    Локация{" "}
                    <span className="object__value">{project.location} </span>
                  </li>
                )}
                {project.realizationPeriod && (
                  <li className="object__param">
                    Локация{" "}
                    <span className="object__value">{project.location}</span>
                  </li>
                )}
                <li className="object__param">
                  Стиль <span className="object__value">{project.style}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {project.layoutBefore && (
          <div className="object__container">
            <h2 className="object__title">Планировочное решение</h2>
            <div className="object__slider">
              <img-comparison-slider>
                <img
                  slot="first"
                  src={project.layoutBefore}
                  className="object__layout"
                  alt="До"
                  loading="lazy"
                />
                <div className="object__stick" slot="handle">
                  <div className="object__arrows">
                    <img
                      src={arrowLeft}
                      className="object__arrow"
                      alt="Стрелка влево"
                    />
                    <img
                      src={arrowLeft}
                      className="object__arrow"
                      alt="Стрелка вправо"
                    />
                  </div>
                </div>
                <img
                  slot="second"
                  src={project.layoutAfter}
                  className="object__layout l"
                  alt="После"
                  loading="lazy"
                />
              </img-comparison-slider>
            </div>
          </div>
        )}
        {project.video && (
          <div className="object__container">
            <h2 className="object__title object__title_type_video">
              Видеообзор интерьера
            </h2>
            <CustomVideoPlayer
              videoSrc={project.video}
              poster={project.videoPoster}
            />
          </div>
        )}

        <div className="object__container object__container_type_gallery">
          <h2 className="object__title">Галерея проекта</h2>
          {project.objectColumns === 2 && size.width >= 576 ? (
            <div className="object__gallery">
              <div className="object__gallery-column">
                {project.images
                  .map((image, index) => index)
                  .filter((_, index) => index % 2 === 0)
                  .map((originalIndex) => (
                    <LoadableImage
                      src={project.images[originalIndex]}
                      className="object__gallery-image"
                      alt="Фотография объекта"
                      key={`odd-${originalIndex}`}
                      onClick={() => handleImageClick(originalIndex)}
                    />
                  ))}
              </div>
              <div className="object__gallery-column">
                {project.images
                  .map((image, index) => index)
                  .filter((_, index) => index % 2 !== 0)
                  .map((originalIndex) => (
                    <LoadableImage
                      src={project.images[originalIndex]}
                      className="object__gallery-image"
                      alt="Фотография объекта"
                      key={`even-${originalIndex}`}
                      onClick={() => handleImageClick(originalIndex)}
                    />
                  ))}
              </div>
            </div>
          ) : (
            <div className="object__gallery object__gallery_type_column">
              {project.images.map((image, index) => (
                <LoadableImage
                  src={image}
                  className="object__gallery-image"
                  alt="Фотография объекта"
                  key={`odd-${index}`}
                  onClick={() => handleImageClick(index)}
                />
              ))}
            </div>
          )}
        </div>
        {popupIndex !== null && (
          <ImagePopup
            images={project.images}
            currentIndex={popupIndex}
            onClose={closeImagePopup}
          />
        )}
      </div>
    </section>
  );
};

export default Object;
