import "./Main.css";
import React from "react";
import Promo from "../Promo/Promo";
import Directions from "../Directions/Directions";
import Hall from "../Hall/Hall";
import Scheme from "../Scheme/Scheme";
import Team from "../Team/Team";
import Price from "../Price/Price";

import { works, realizedWorks } from "../../utils/constants";
import Questions from "../Questions/Questions";
import Quiz from "../Quiz/Quiz";
import Feedback from "../Feedback/Feedback";
import manuscriptImage from "../../images/Object/manuscript.svg";
import Advantages from "../Advantages/Advantages";
import LoadableImage from "../LoadableImage/LoadableImage";

const Main = ({ handleOpenPopup, handleOpenPopupRequest }) => {
  return (
    <main className="main">
      <Promo
        title={"Готовые интерьеры под ключ"}
        handleOpenPopup={handleOpenPopup}
      />
      <LoadableImage
        src={manuscriptImage}
        className="main__manuscript"
        alt="Рукопись «Ваше новое качество жизни»"
      />
      <Directions />
      <Hall
        title={"Галерея работ"}
        projects={works}
        buttonTitle={"Посмотреть все работы"}
      />
      <Scheme />
      <Hall
        title={"Реализованные проекты"}
        projects={realizedWorks}
        text={true}
        buttonTitle={"Записаться на экскурсию на живой объект"}
        handleOpenPopupRequest={handleOpenPopupRequest}
      />
      <Team />
      <Advantages />
      <Price />
      <Feedback />
      <Questions />
      <Quiz handleOpenPopupRequest={handleOpenPopupRequest} />
    </main>
  );
};

export default Main;
